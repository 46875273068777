import FooterModalSlack from './FooterModalSlack';
import FooterModalSetRosterReport from './FooterModalSetRosterReport';

export default ({ user }) => {
  let isAdmin = false;
  if (user && user.attributes && user.attributes['custom:admin']) {
    isAdmin = true;
  }
  return (
    <div className="footer">
      <FooterModalSlack />
      {isAdmin ? <FooterModalSetRosterReport /> : null}
    </div>
  );
};
