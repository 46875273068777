import { useState } from 'react';
import { Modal, Button, Loader, Message } from 'semantic-ui-react';
import request from '../utils/request';

export default () => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [settingRosterReport, setSettingRosterReport] = useState(false);

  async function triggerRosterReport() {
    setSettingRosterReport(true);
    setSuccess(null);
    setError(null);
    const response = await request('post', process.env.REACT_APP_EXPRESS_URL + 'setRosterReport');
    if (response.error) {
      setError('Error forcing roster set: ' + response.errorMessage);
    } else {
      setSuccess('Succesfully set roster report. If you need to run this again, refresh the page.');
    }
    setSettingRosterReport(false);
  }

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button primary content="Force roster set?" />}
    >
      <Modal.Header>Force a roster set</Modal.Header>
      <Modal.Content>
        <Loader active={settingRosterReport} />
        <Message>This will trigger a roster set which will take any upcoming games (next 7 days) and make their rosters "live" on the website and mobile app.</Message>
        {error ? <Message
          error
          content={error}
        /> : null}
        {success ? <Message
          content={success}
        /> : null}
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          disabled={success}
          content="Are you sure that you want to set the roster"
          onClick={triggerRosterReport}
        />
      </Modal.Actions>
    </Modal>
  );
};
